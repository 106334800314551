import React from "react";
import {ThemeProvider, Button } from '@mui/material';

import {ThemeMain, ThemeCustom} from '../../styles/Theme'


const ButtonMain = (props) =>{
    return(
        <ThemeProvider theme={ThemeCustom}>
            <Button variant={props.variant} type={props.type} onClick={props.onClick} className={props.class} disabled={props.disabled}>
                {props.name}
            </Button>
        </ThemeProvider>
    );
}

export default ButtonMain;