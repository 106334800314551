import React, {useEffect, useState} from "react";

import { ThemeProvider, Container} from "@mui/material"
import { ThemeCustom } from "../../styles/Theme";

import Logo from '../../assets/Logo.svg'
import ButtonMain from "../button/Button_main";


const Navbar = () => {

    const getContact = () => {
        console.log('getContact')
        const newWindow = window.open('https://forms.gle/4ZpuC6cJ3sATjTkaA', '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className="py-5 fixed top-0 w-full bg-transparent backdrop-blur-md z-50 bg-white bg-opacity-40">
                <Container maxWidth="lg" className="flex justify-between items-center ">
                    <img src={Logo} alt="TalentScout logo" className="w-full max-w-[15rem] cursor-pointer"/>
                    <ButtonMain 
                        variant='secondary' 
                        type='button' 
                        disabled={false} 
                        name='Request a Demo'
                        class='capitalize hidden sm:block'
                        onClick={getContact}
                    />
                </Container>
            </div>
        </ThemeProvider>
    )
}

export default Navbar;