import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import {ThemeProvider, CssBaseline  } from '@mui/material';
import {ThemeMain, ThemeCustom} from './styles/Theme'
import './styles/custom_styles.scss'

import Home from './views/Home';

const App = () => {

  return (
    <ThemeProvider theme={ThemeMain}>
        <CssBaseline enableColorScheme/>
        <Routes>
          <Route path='/' element={<Home/>} />
        </Routes>
    </ThemeProvider>
  );

}

export default App;
