import React, {useEffect, useState, useRef} from "react";
import ReactPlayer from 'react-player'

import { ThemeProvider, Typography} from "@mui/material"
import { ThemeCustom } from "../../styles/Theme";

import VideoPlayer from "../videoPlayer/videoPlayer";

import videoImage from '../../assets/screen-preview.svg'

const VideoSection = () => {

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className="sm:my-[10rem] my-[6rem]">
                <div className="flex justify-center">
                    <VideoPlayer/>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default VideoSection;