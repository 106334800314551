import React, {useEffect, useState} from "react";

import { ThemeProvider, Container, Typography} from "@mui/material"
import { ThemeCustom} from "../../styles/Theme";

import Logo from '../../assets/Logo.svg'
import emailIcon from '../../assets/email.svg'
import linkedInIcon from '../../assets/linkedin.svg'


const Footer = () => {

    const getContact = () => {
        console.log('getContact')
    }

    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className="bg-primary ">
                <Container maxWidth="lg">
                    <div className="flex justify-between items-center py-6">
                        <div className="flex items-center">
                            {/* <img src={Logo} alt="TalentScout logo" className="w-full max-w-[15rem] cursor-pointer"/> */}
                        </div>
                        <Typography component={'div'} variant="text14" className="text-culturedDark ">
                            © TalentScout 2024
                        </Typography>
                        <div className="flex items-center">
                            <a href="mailto:talentscout.co@gmail.com" title="Email" className="mr-7 flex items-center">
                                <img src={emailIcon} alt="email" className="w-[2rem]"/>
                            </a>
                            <a href="https://www.linkedin.com/company/talentscoutai/" title="Linkedin" target="blank" className="flex items-center">
                                <img src={linkedInIcon} alt="linkedin" className="w-[2rem]"/>
                            </a>
                        </div>
                    </div>
                </Container>
            </div>
        </ThemeProvider>
    )
}

export default Footer;