import React, {useEffect, useState} from "react";

import { ThemeProvider, Container } from "@mui/material"
import { ThemeCustom } from "../styles/Theme";

import Navbar from "../components/navbar/Navbar";
import Hero from "../components/hero/Hero"; 
import BenefitSection from "../components/sections/benefitSection";
import ActionSection from "../components/sections/actionSection";
import Footer from "../components/navbar/Footer";
import VideoSection from "../components/sections/videoSection";

const Home = () => {
    return(
        <ThemeProvider theme={ThemeCustom}>
            <div className="relative">
                <Navbar/>
                <Container maxWidth="lg">
                    <Hero/>
                    <VideoSection/>
                    <BenefitSection/>
                </Container>
                <ActionSection/>
                <Footer/>
            </div>
        </ThemeProvider>        
    )
}

export default Home;